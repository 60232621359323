import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/ubuntu/flomesh.io-main/src/layouts/Fixed/DocMdx.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "core"
    }}>{`Core`}</h1>
    <ul>
      <li parentName="ul">{`Overhaul to the object lifecycle management to solve a few hardcore crashes`}</li>
      <li parentName="ul">{`Supports LevelDB as a storage for codebases`}</li>
      <li parentName="ul">{`New admin proxy mode`}</li>
      <li parentName="ul">{`Retry failed codebase downloading during startup`}</li>
    </ul>
    <h1 {...{
      "id": "pipyjs"
    }}>{`PipyJS`}</h1>
    <ul>
      <li parentName="ul">{`Full implementation to standard JavaScript Array, Math, Date API without iterators and timezones`}</li>
      <li parentName="ul">{`Standard-conformant JavaScript String API with UTF-32 semantics and UTF-8 representation internally`}</li>
    </ul>
    <h1 {...{
      "id": "api"
    }}>{`API`}</h1>
    <ul>
      <li parentName="ul">{`Metrics and statistics API for monitoring, with internal support for Prometheus exposition format`}</li>
      <li parentName="ul">{`New `}<inlineCode parentName="li">{`URLSearchParams`}</inlineCode>{` API`}</li>
      <li parentName="ul">{`Data API supports generating arbitrary binary data`}</li>
      <li parentName="ul">{`Handle CDATA in `}<inlineCode parentName="li">{`XML.decode()`}</inlineCode>{` and `}<inlineCode parentName="li">{`XML.encode()`}</inlineCode></li>
      <li parentName="ul">{`Support programmatically shutdown by `}<inlineCode parentName="li">{`pipy.exit()`}</inlineCode></li>
      <li parentName="ul">{`Printing objects as JSON with `}<inlineCode parentName="li">{`console.log()`}</inlineCode></li>
    </ul>
    <h1 {...{
      "id": "filters"
    }}>{`Filters`}</h1>
    <h2 {...{
      "id": "general"
    }}>{`General`}</h2>
    <ul>
      <li parentName="ul">{`Support read/write timeouts for inbound connections`}</li>
      <li parentName="ul">{`Support read/write/connect timeouts for outbound connections`}</li>
      <li parentName="ul">{`Support signal driven tasks`}</li>
      <li parentName="ul">{`Support listening address binding`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`use`}</inlineCode>{` filter supports module(plugin) chains and round-trip filter paths`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`mux`}</inlineCode>{` filters support objects as a weak key to the shared session`}</li>
      <li parentName="ul">{`LoadBalancers support dynamic unhealthy target cache`}</li>
    </ul>
    <h2 {...{
      "id": "http"
    }}>{`HTTP`}</h2>
    <ul>
      <li parentName="ul">{`Basic HTTP/2 support without flow control`}</li>
      <li parentName="ul">{`HTTP codec standard conformance improvements`}</li>
      <li parentName="ul">{`Support for HTTP tunneling and WebSocket`}</li>
      <li parentName="ul">{`Actively shutdown long-lived connections around a reload`}</li>
      <li parentName="ul">{`Automatically chunk HTTP/1 message bodies when they are too large`}</li>
    </ul>
    <h2 {...{
      "id": "tls"
    }}>{`TLS`}</h2>
    <ul>
      <li parentName="ul">{`Support mTLS between worker nodes and the repo node`}</li>
      <li parentName="ul">{`New `}<inlineCode parentName="li">{`handleTLSClientHello`}</inlineCode>{` filter to extract TLS client hello information`}</li>
      <li parentName="ul">{`New `}<inlineCode parentName="li">{`detectProtocol`}</inlineCode>{` filter for telling apart TLS and plain-TCP connections`}</li>
      <li parentName="ul">{`Improved TLS performance`}</li>
      <li parentName="ul">{`OpenSSL library bumped to version 1.1.1-m`}</li>
      <li parentName="ul">{`Option to build with external OpenSSL library`}</li>
    </ul>
    <h2 {...{
      "id": "socks"
    }}>{`SOCKS`}</h2>
    <ul>
      <li parentName="ul">{`New filter `}<inlineCode parentName="li">{`connectSOCKS`}</inlineCode>{` for the client side`}</li>
      <li parentName="ul">{`Renamed `}<inlineCode parentName="li">{`proxySOCKS`}</inlineCode>{` to `}<inlineCode parentName="li">{`acceptSOCKS`}</inlineCode></li>
    </ul>
    <h2 {...{
      "id": "mqtt"
    }}>{`MQTT`}</h2>
    <ul>
      <li parentName="ul">{`Simple MQTT codec filters for extracting MQTT packet information`}</li>
    </ul>
    <h1 {...{
      "id": "gui"
    }}>{`GUI`}</h1>
    <ul>
      <li parentName="ul">{`Script editor supports IntelliSense`}</li>
      <li parentName="ul">{`Generate pipeline graph from script enclosed in a function scope`}</li>
      <li parentName="ul">{`Builtin documentation with full tutorial and localization`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      